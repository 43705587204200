
import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import useAlert from "../../composables/Alert";
import { Modal } from "bootstrap";

export default defineComponent({

  name: "LojasBloqueadas",
  
  components: {
  },

  setup() {

    const { showTimeAlert } = useAlert()

    const loadSalvar = ref(false)



    const arrayLojas: Ref<any[]> = ref([])
    const arrayLojasBloqueadas: Ref<any[]> = ref([])







    async function salvar() {

      try {
        
        loadSalvar.value = true;

        const { data } = await Api.post("bloqueiaLojas", { idLojas: arrayLojasBloqueadas.value });

        loadSalvar.value = false;

        if(!data.lojasBloqueadas) return showTimeAlert(data?.mensagem, "error");

        showTimeAlert('configuração salva com sucesso!', "success")

      } catch (error) { loadSalvar.value = false; }

    }




    onMounted(async() => {

      try {
        
        
        arrayLojas.value = (await Api.get('getAllStores') as any).data.lojas || [];
        
        arrayLojasBloqueadas.value = (await Api.get('getLojasBloqueadas') as any).data.lojas || [];


      } catch (error) { console.log('erro na função onMounted()', error) }

    })




    return {

      loadSalvar,
      arrayLojas,
      arrayLojasBloqueadas,
      salvar


    }



  }

});
